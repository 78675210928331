import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { View, StyleSheet } from "react-native";
import Row from "@base/Row";
import { colors } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    onPress: () => void;
    icon?: string;
    title?: string;
    counter?: string | number;
}

interface State { }

const { t } = i18n;

export default class SectionTitle extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <PressableBase onPress={this.props.onPress}>
                <Row>
                    <BoldText fontSize={16} style={{ position: "relative" }}>
                        {this.props.title}
                    </BoldText>
                    <View style={{ flex: 1, justifyContent: "center" }}>
                        {this.props.counter && (
                            <View style={styles.bubble}>
                                <BoldText align="center" fontSize={10} color={colors.labelText || colors.white}>
                                    {this.props.counter}
                                </BoldText>
                            </View>
                        )}
                    </View>
                </Row>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    bubble: {
        position: "absolute",
        marginLeft: 5,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
    },
});

const nunitoBold = require("./HelveticaNeueBold.otf")
const nunitoLight = require("./HelveticaNeueThin.otf")
const nunitoRegular = require("./HelveticaNeueRoman.otf")
const nunitoSemiBold = require("./HelveticaNeueMedium.otf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
